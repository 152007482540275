<template>
  <v-container>
    <v-row>
      <v-col>
        <h3 class="text-center">{{ pageTitle }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" align="center">
        <p class="font-weight-bold">Imported</p>
        <p>(Import vouchers)</p>
        <v-btn :disabled="true">
          DONE
        </v-btn>
      </v-col>
      <v-col cols="4" align="center">
        <p class="font-weight-bold">Mapping</p>
        <p>(Match imported vouchers)</p>
        <v-btn :disabled="true">
          DONE
        </v-btn>
      </v-col>
      <v-col cols="4" align="center">
        <p class="font-weight-bold">Completed(Process)</p>
        <p>(Mark matched vouchers paid?)</p>
        <v-btn
          :disabled="isUpdated"
          depressed
          color="primary"
          @click="updateMatchedTransactionStatus"
        >
          {{ isUpdated ? 'Done' : 'Proceed' }}
        </v-btn>
      </v-col>
    </v-row>
    <p></p>
    <hr>
    <p></p>
    <h3 class="text-center">MATCHED VOUCHERS</h3>
    <template>
      <v-data-table
        :headers="transactionDetails.matchedData.headers"
        :items="transactionDetails.matchedData.data"
        :hide-default-footer="true"
        disable-pagination
        class="elevation-1"
      >
        <template slot="body.append">
          <tr class="pink--text">
              <td colspan="3">
                Total for matched vouchers:
                ${{amountFormat(transactionDetails.matchedData.totalAmount) }}
              </td>
          </tr>
      </template>
      </v-data-table>
    </template>
    <p></p>
    <hr>
    <p></p>
    <h3 class="text-center">UNMATCHED VOUCHERS</h3>
    <template>
      <v-data-table
        :headers="transactionDetails.unmatchedData.headers"
        :items="transactionDetails.unmatchedData.data"
        :hide-default-footer="true"
        disable-pagination
        class="elevation-1"
      >
        <template slot="body.append">
          <tr class="pink--text">
              <td colspan="3">
                Total for unmatched vouchers:
                ${{ amountFormat(transactionDetails.unmatchedData.totalAmount) }}
              </td>
          </tr>
        </template>
      </v-data-table>
    </template>
    <v-overlay :value="overlay">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="value"
        color="primary"
      >
        {{ value }}
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import { importFileProcessedData } from '../../util/shared/tmc-global';
import { camelCaseToWords, amountFormat } from '../../util/shared/vue-global';

const initdata = () => ({
  pageTitle: '',
  overlay: false,
  value: 0,
  isUpdated: false,
});

export default {
  name: 'FileProcessingDetails',
  data: () => initdata(),
  created() {
    const profileAction = this.$route.params.type;
    this.$store.dispatch(`Bulk/${importFileProcessedData[profileAction]}`);
    this.pageTitle = `${camelCaseToWords(profileAction)}`;
  },
  computed: {
    transactionDetails() {
      return this.$store.state.Bulk.processedFileData;
    },
  },
  methods: {
    ...mapMutations([
      'setPrintScreen',
    ]),
    amountFormat,
    updateMatchedTransactionStatus() {
      if (window.confirm('Are you sure want to update all matched records?')) {
        const matchedData = this.transactionDetails.matchedData.data;
        this.overlay = true;
        for (let i = 0; i < matchedData.length; i += 1) {
          const item = matchedData[i];
          const upsItem = { id: item.transactionId, status: 'PAID', finalized: item.date };
          this.$store.dispatch('OD/upsTransaction', {
            transaction: upsItem,
          });
          this.value = parseInt((100 * (i + 1)) / matchedData.length, 10);
        }
        this.overlay = false;
        this.isUpdated = true;
        this.$store.dispatch('flashInfo', `Successfully ${matchedData.length} records have updated.`).then(() => {
          this.setPrintScreen(true);
          this.$nextTick(() => {
            window.print();
            this.$nextTick(() => {
              this.setPrintScreen(false);
            });
          });
        });
      }
    },
  },
};
</script>
